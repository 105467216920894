



























































































































































































































































































































































import ViewModel from '@/views/laboratory/Index.ts';
export default ViewModel;
