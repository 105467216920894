var render = function () {var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;
return _c('div',{attrs:{"id":"container"}},[_c('main',[_vm._m(0),_c('div',{staticClass:"section_right"},[_vm._m(1),_c('div',{staticClass:"footer",on:{"click":_vm.goToGuide}},[_c('img',{attrs:{"src":require("../../assets/hoban/hobanOn.png"),"alt":"Hoban On"}})])])])]);}
var staticRenderFns = [function () {var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;
return _c('div',{staticClass:"section_left"},[_c('h2',{staticClass:"text_title"},[_vm._v("스마트 라이프 스타일 앱")]),_c('h1',{staticClass:"text_content"},[_c('span',{staticClass:"color_orange"},[_vm._v("호반온,")]),_c('br'),_vm._v(" 호반이 제공하는"),_c('br'),_c('span',{staticClass:"color_orange"},[_vm._v("특별한 경험")]),_vm._v("을"),_c('br'),_vm._v(" 시작해 보세요. ")]),_c('span',{staticClass:"text_footer"},[_vm._v("채들과 함께 합니다.")])]);}/* staticRenderFnsSpliter */,function(){var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;
return _c('div',{staticClass:"container_guide"},[_c('img',{attrs:{"src":require("../../assets/hoban/play.png"),"alt":"Guide"}}),_c('span',{staticClass:"text_guide"},[_vm._v("가이드 보기")])]);}]

export { render, staticRenderFns }