<template>
	<div id="container">
		<main>
			<div class="section_left">
				<h2 class="text_title">스마트 라이프 스타일 앱</h2>
				<h1 class="text_content">
					극동건설이<br/>제공하는<br/>
					<span class="color_orange">특별한 경험</span>을<br />
					시작해 보세요.
				</h1>
				<span class="text_footer">채들과 함께 합니다.</span>
			</div>
			<div class="section_right">
				<div class="container_guide">
					<img src="../../assets/hoban/play.png" alt="Guide" />
					<span class="text_guide">가이드 보기</span>
				</div>
				<div class="footer" @click="goToGuide">
					<img src="../../assets/kukdong/btn.svg" alt="Hoban On" />
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	mounted() {
		this.$vuetify.theme.dark = false;
	},
	beforeDestroy() {
		this.$vuetify.theme.dark = true;
	},
	methods: {
		goToGuide() {
			window.location.href = 'https://bit.ly/3a22CYm';
		},
	},
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");

#container {
	font-family: 'Pretendard', sans-serif;
}

/* Main layout */
main {
	color: #000;
	background-color: #0069b3;
	width: 960px;
	height: 840px;
	border-radius: 20px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 80px;
	display: flex;
	justify-content: space-between;
	background-image: url("../../assets/hoban/chaedle_pc.png");
	background-repeat: no-repeat;
	background-position: -160px center;
	background-size: 70%;
}

/* Left section */
.section_left {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.section_left .text_title {
	font-size: 40px;
	font-weight: 500;
}

.section_left .text_content {
	color: #000 !important;
	margin-top: 55px;
	font-size: 60px;
	font-weight: 900;
	line-height: 80px;
	padding-bottom: 180px;
}

.section_left .text_footer {
	font-size: 24px;
	font-weight: 600;
	line-height: 80px;
	color: #ddd;
}

/* Right section */
.section_right {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.section_right img {
	width: 160px;
	height: 164px;
}

.container_guide {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;
	color: #fff;
}

.container_guide img {
	width: 32px !important;
	height: 32px !important;
}

.text_guide {
	font-size: 20px;
	font-weight: 500;
	margin-left: 12px;
}

.color_orange {
	color: #fff;
}

/* Responsive styling */
@media (max-width: 768px) {
	main {
		width: 100%;
		height: 100%;
		padding: 40px;
		background-size: 100%;
	}
	
	.section_right {
		height: 100%;
		margin-top: 20px;
		position: absolute;
		right: 20px; /* Right alignment */
	}
	
	.section_right > .footer {
		position: relative;
		bottom: 10%;
	}
	
	.section_left .text_title {
		font-size: 24px;
		font-weight: 500;
		position: relative;
		top: 25%;
	}
	
	.section_left .text_content {
		margin-top: 20px;
		font-size: 36px;
		line-height: 50px;
		padding-bottom: 40px;
	}
	
	.section_left .text_footer {
		font-size: 18px;
		line-height: 50px;
		font-weight: normal;
	}
	
	.container_guide img {
		width: 80px;
		height: 80px;
	}
	
	.text_guide {
		font-size: 18px;
		margin-left: 8px;
	}
	
	.footer img {
		width: 150px;
		height: 150px;
	}
}
</style>